// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ComponentViewportProvider, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Download from "../codeFile/Download_All_Button.tsx";
const DownloadFonts = getFonts(Download);
const DownloadControls = getPropertyControls(Download);

const enabledGestures = {aWPG_Jpu3: {hover: true}};

const cycleOrder = ["aWPG_Jpu3"];

const serializationHash = "framer-T0hvw"

const variantClassNames = {aWPG_Jpu3: "framer-v-zsuxx5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({file, height, id, width, ...props}) => { return {...props, mCZi9jQB2: file ?? props.mCZi9jQB2} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;file?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mCZi9jQB2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aWPG_Jpu3", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zsuxx5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aWPG_Jpu3"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({"aWPG_Jpu3-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-66v4ch-container"} layoutDependency={layoutDependency} layoutId={"TE7Y6AudJ-container"}><Download downloadFileName={"All Assets"} file={mCZi9jQB2} height={"100%"} id={"TE7Y6AudJ"} layoutId={"TE7Y6AudJ"} style={{width: "100%"}} tc={"rgb(255, 255, 255)"} tint={"rgb(44, 44, 44)"} title={"Download all assets"} width={"100%"} {...addPropertyOverrides({"aWPG_Jpu3-hover": {tc: "rgb(0, 0, 0)", tint: "rgb(212, 212, 212)"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T0hvw.framer-1jydo9i, .framer-T0hvw .framer-1jydo9i { display: block; }", ".framer-T0hvw.framer-zsuxx5 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: 169px; }", ".framer-T0hvw .framer-66v4ch-container { flex: none; height: auto; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-T0hvw.framer-zsuxx5 { gap: 0px; } .framer-T0hvw.framer-zsuxx5 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-T0hvw.framer-zsuxx5 > :first-child { margin-top: 0px; } .framer-T0hvw.framer-zsuxx5 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 113
 * @framerIntrinsicWidth 169
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"QdEU2yPJf":{"layout":["fixed","auto"]}}}
 * @framerVariables {"mCZi9jQB2":"file"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMqQ8r2zAH: React.ComponentType<Props> = withCSS(Component, css, "framer-T0hvw") as typeof Component;
export default FramerMqQ8r2zAH;

FramerMqQ8r2zAH.displayName = "Brandkit / Download all assets button";

FramerMqQ8r2zAH.defaultProps = {height: 113, width: 169};

addPropertyControls(FramerMqQ8r2zAH, {mCZi9jQB2: DownloadControls?.["file"] && {...DownloadControls["file"], __defaultAssetReference: "", description: undefined, hidden: undefined, title: "File"}} as any)

addFonts(FramerMqQ8r2zAH, [{explicitInter: true, fonts: []}, ...DownloadFonts], {supportsExplicitInterCodegen: true})